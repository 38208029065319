<template>
    <div class="text-center">
        <div v-if="sequenceStage == null" class="white--text">
            <span class="text-h4">Press button</span>
            <br>
            <span class="text-h3" >???</span>
        </div>
        <div v-else>
            <span class="text-h4">Press button</span>
            <br>
            <span class="text-h3">{{ sequenceStage.pushText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['sequenceStage']
}
</script>

<style>

</style>