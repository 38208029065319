<template>
    <div class="text-center">
        <div>
            <span class="text-h4">
                Sequence complete
                <v-icon large>mdi-party-popper</v-icon>
            </span>
        </div>

        <v-btn
        color="secondary"
        class="mt-3"
        @click="restart">
            Restart
            <v-icon right>mdi-restart</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    methods: {
        restart() {
            this.$emit('restart');
        }
    }
}
</script>

<style>

</style>