<template>
    <v-row align="center">
        <v-col class="text-center">
            <v-avatar
            v-for="chip in 5" :key="'stage-'+chip"
            :color="chip <= stage ? 'green accent-4' : 'teal darken-4'"
            class="ma-1"
            size="40"
            style="border: 3px solid black"
            />
        </v-col>
        <v-divider vertical/>
        <v-col cols="auto">
            <v-btn color="red" icon
            @click="restart">
                <v-icon>mdi-restart</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: { 
        stage: Number
    },
    methods: {
        restart() {
            this.$emit('restart');
        },
    }
}
</script>

<style>

</style>