<template>
    <div>
        <v-row justify="center">
            <v-col v-for="number in 4" :key="'btn-'+number"
            cols="6" sm="3">
                <v-btn 
                block
                x-large
                color="warning"
                @click="addNumber(number)">
                    {{ number }}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
    }),
    methods: {
        addNumber(number) {
            this.$emit('addNumber', number);
        }
    }
}
</script>

<style>

</style>