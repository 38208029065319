<template>
    <v-container>
        <show-stage :stage="(stage == 4 && phase == 1) ? 5 : stage" @restart="resetModule" />

        <v-divider class="my-3"/>

        <div v-if="phase === 0">
            <push-button :sequenceStage="null"/>
            
            <v-divider class="my-3"/>

            <h1>Number on display</h1>
            <number-select @addNumber="addScreenNumber"/>
            
        </div>

        <div v-else-if="phase === 1">
            <push-button :sequenceStage="sequence[stage]"/>

            <v-divider class="my-3"/>

            <div v-if="stage < 4">
                <h1>Select {{ sequence[stage].next }}</h1>
                <number-select @addNumber="addPushNumber"/>
            </div>

            <sequence-complete v-else @restart="resetModule" />
            
        </div>
    </v-container>
</template>

<script>
import numberSelect from '../components/modules/memory/number-select.vue'
import PushButton from '../components/modules/memory/push-button.vue';
import SequenceComplete from '../components/modules/memory/sequence-complete.vue';
import ShowStage from '../components/modules/memory/show-stage.vue';
export default {
    components: { numberSelect, ShowStage, PushButton, SequenceComplete },
    data: () => ({
        sequence: [],
        stage: 0,
        phase: 0,
        next: 'label',
    }),
    methods: {
        addScreenNumber(n) {
            this.sequence.push({ screen: n });
            this.phase++;
            this.calculateResult();
        },
        addPushNumber(n) {
            const seq = this.sequence;
            const stage = this.stage;
            const next = seq[stage].next;

            this.sequence[stage][next] = n;
            this.stage++;
            this.phase = 0;
        },
        resetModule() {
            this.sequence = [];
            this.stage = 0;
            this.phase = 0;
        },
        calculateResult() {
            const seq = this.sequence;
            const stage = this.stage;
            let btn = null;

            switch (stage) {
                case 0:
                    switch (seq[0].screen) {
                        case 1: //same as 2
                        case 2:
                            btn = 2;
                            break;
                        case 3:
                            btn = 3;
                            break;
                        case 4:
                            btn = 4;
                            break;
                    }
                    Object.assign(seq[0], { position: btn, label: null });
                    break;
                case 1:
                    switch (seq[1].screen) {
                        case 1:
                            btn = 4;
                            Object.assign(seq[1], { position: null, label: btn });
                            break;
                        case 2:
                            btn = seq[0].position;
                            Object.assign(seq[1], { position: btn, label: null });
                            break;
                        case 3:
                            btn = 1;
                            Object.assign(seq[1], { position: btn, label: null });
                            break;
                        case 4:
                            btn = seq[0].position;
                            Object.assign(seq[1], { position: btn, label: null });
                            break;
                    }
                    break;
                case 2:
                    switch (seq[2].screen) {
                        case 1:
                            btn = seq[1].label;
                            Object.assign(seq[2], { position: null, label: btn });
                            break;
                        case 2:
                            btn = seq[0].label;
                            Object.assign(seq[2], { position: null, label: btn });
                            break;
                        case 3:
                            btn = 3;
                            Object.assign(seq[2], { position: btn, label: null });
                            break;
                        case 4:
                            btn = 4;
                            Object.assign(seq[2], { position: null, label: btn });
                            break;
                    }
                    break;
                case 3:
                    switch (seq[3].screen) {
                        case 1:
                            btn = seq[0].position;
                            Object.assign(seq[3], { position: btn, label: null });
                            break;
                        case 2:
                            btn = 1;
                            Object.assign(seq[3], { position: btn, label: null });
                            break;
                        case 3: //same as 4
                        case 4:
                            btn = seq[1].position;
                            Object.assign(seq[3], { position: btn, label: null });
                            break;
                    }
                    break;
                case 4:
                    switch (seq[4].screen) {
                        case 1:
                            btn = seq[0].label;
                            break;
                        case 2:
                            btn = seq[1].label;
                            break;
                        case 3:
                            btn = seq[3].label;
                            break;
                        case 4:
                            btn = seq[2].label;
                            break;
                    }
                    Object.assign(seq[4], { position: null, label: btn });
                    break;
            }

            seq[stage].next = seq[stage].position == null ? 'position' : 'label';
            seq[stage].pushText = `${seq[stage].position == null ? 'label' : 'position'} ${btn}`;
            
            this.sequence = seq;
            return seq;
        }
    }
}
</script>

<style>

</style>